<template>
  <div id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <a-table :columns="columns" :data-source="tableData" :row-key="record => record.id" :pagination="false"
        :loading="tableLoading">
        <span slot="operation" slot-scope="operation, record">
          <a v-if="record.id === 1" class="margin_right10" type="primary" @click="kfClick">看法列表</a>
          <a class="margin_right10" type="primary" @click="editClick(record)">进入</a>
        </span>
      </a-table>
    </a-spin>
    <router-view />
  </div>
</template>

<script>
export default {
  created () {
  },

  data () {
    return {
      tip: "",
      spinning: false,
      tableLoading: false,
      columns: [
        {
          title: "活动名称",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "操作",
          width: "150px",
          dataIndex: "operation",
          key: "operation",
          align: "right",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [
        {
          id: 1,
          title: '水浒活动',
          link: '/appletsManage/subject'
        },
        {
          id: 2,
          title: '四大名著21天优学营',
          link: '/appletsManage/mzYdt'
        },
        {
          id: 3,
          title: '名著一点通',
          link: '/appletsManage/classicsYdt'
        }
      ],
    };
  },

  methods: {
    // 编辑编辑按钮
    editClick (record) {
      this.$router.push(record.link)
    },
    // 点击看法
    kfClick () {
      this.$router.push('/appletsManage/perspective')
    }
  },
};
</script>

<style lang="less" scoped>
#third_Party_order {
  height: 100%;
  overflow: hidden;
}

.avatar {
  width: 100px;
}
</style>

