<template>
	<div id="third_Party_order">
		<a-spin :spinning="spinning" size="large" :tip="tip">
			<div class="top">
				<a-form layout="inline">
					<a-form-item>
						<a-button icon="plus" class="margin_right20" type="primary" @click="addClick('1')">添加</a-button>
					</a-form-item>
				</a-form>
			</div>
			<!-- 表格 -->
			<a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"
				:loading="tableLoading">
				<img v-viewer class="avatar" slot="image" slot-scope="image, record" :src="record.image" alt="封面图" />
				<span slot="enableFlag" slot-scope="enableFlag, record">
					<a-badge v-if="record.enableFlag == 0" status="warning" text="已下架" />
					<a-badge v-else status="success" text="已上架" />
				</span>
				<span slot="operation" slot-scope="operation, record">
					<a class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
					<template>
						<a-popconfirm title="确认要删除此分类吗？" ok-text="确定" cancel-text="取消" @confirm="deleteClick(record)">
							<a id="copy_memberNo" type="primary" class="margin_right10">删除</a>
						</a-popconfirm>
					</template>
					<template v-if="record.enableFlag == 0">
						<a-popconfirm title="确认要上架此条数据吗？" ok-text="确定" cancel-text="取消" @confirm="isLowerShelfClick(record)">
							<a id="copy_memberNo" type="primary">上架</a>
						</a-popconfirm>
					</template>
					<template v-else>
						<a-popconfirm title="确认要下架此条数据吗？" ok-text="确定" cancel-text="取消" @confirm="isLowerShelfClick(record)">
							<a id="copy_memberNo" type="primary">下架</a>
						</a-popconfirm>
					</template>
				</span>
			</a-table>

			<MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading" />

			<!-- 返回顶部 -->
			<a-back-top :target="targetFn" :visibilityHeight="100" />

			<a-modal v-model="visibleShow" :title="titleSize" @ok="handleOk" @cancel="handleCancel">
				<a-form-model v-if="visibleShow" ref="ruleForm" :model="form" :rules="rules">
					<a-form-model-item ref="name" label="名称" prop="name">
						<a-input v-model="form.name" placeholder="请输入名称" />
					</a-form-model-item>
					<a-form-model-item ref="bannerImg" label="介绍图" prop="bannerImg">
						<UpdataImg @avatarfns="imgBannerFn" :imageUrlNum="1" :imgUrl="bannerImg" :urlSize="500"></UpdataImg>
						<span v-if="isErrorSize" style="color:red">请上传图片</span>
					</a-form-model-item>
					<a-form-model-item ref="herf" label="小程序跳转路径">
						<a-input v-model="form.herf" placeholder="请输入小程序跳转路径" style="width: 100%" />
					</a-form-model-item>
					<a-form-model-item ref="enableFlag" label="上架设置" prop="enableFlag">
						<a-radio-group v-model="form.enableFlag" name="radioGroup">
							<a-radio :value="1">立即上架</a-radio>
							<a-radio :value="0">暂不上架</a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item ref="dateTime" label="有效期" prop="dateTime">
						<a-range-picker v-model="form.dateTime" show-time @change="dateTimeChange" style="width: 100%;" />
					</a-form-model-item>
					<a-form-model-item ref="sort" label="排序" prop="sort">
						<a-input-number v-model="form.sort" placeholder="请输入排序" style="width: 100%" />
					</a-form-model-item>
				</a-form-model>
			</a-modal>
		</a-spin>
		<router-view />
	</div>
</template>

<script>
import UpdataImg from "@/components/file/updataImg";
import { activeAdd, activeDelete, activeEnableFlag, activeList, activeUpdate } from "@/request/api/appletsManage";
import MyPagination from "@/components/pagination/MyPagination";
import moment from "moment";
export default {
	components: { UpdataImg, MyPagination },
	created () {
		this.getInitFn()
	},

	data () {
		return {
			visibleShow: false,
			isErrorSize: false,
			tableLoading: false,

			tip: "",
			titleSize: '',

			spinning: false,
			activeKey: 1,
			keepLoading: false,

			bannerImg: [],

			columns: [
				{
					title: "名称",
					dataIndex: "title",
					key: "title",
				},
				{
					title: "封面图",
					dataIndex: "image",
					key: "image",
					scopedSlots: { customRender: "image" },
				},
				{
					title: "排序",
					dataIndex: "sort",
					key: "sort",
				},
				{
					title: "状态",
					dataIndex: "enableFlag",
					key: "enableFlag",
					scopedSlots: { customRender: "enableFlag" },
				},
				{
					title: "创建时间",
					dataIndex: "createTime",
					key: "createTime",
				},
				{
					title: "操作",
					dataIndex: "operation",
					key: "operation",
					scopedSlots: { customRender: "operation" },
				},
			],
			tableData: [],

			pageNo: 1,
			pageSize: 20,
			count: 0,

			form: {
				id: '',
				name: '',
				sort: '',
				herf: '',
				bannerImg: '',
				enableFlag: 1,
				dateTime: [],
				startTime: '',
				endTime: '',
			},

			rules: {
				name: [
					{ required: true, message: "请输入名称", trigger: "blur" },
					{ max: 12, message: "最多输入12个字符", trigger: "change" },
				],
				sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
				bannerImg: [{ required: true, message: "" }],
				enableFlag: [{ required: true, message: "上架设置", trigger: "change" }],
				dateTime: [{ required: true, message: "请选择有效期", trigger: "change" }],
			},
		};
	},

	methods: {
		moment,
		dateTimeChange (val, dateString) {
			console.log(val, dateString)
			this.form.startTime = dateString[0]
			this.form.endTime = dateString[1]
		},

		isLowerShelfClick (record) {
			activeEnableFlag({
				id: record.id,
				enableFlag: record.enableFlag == 0 ? 1 : 0
			}).then(({ code }) => {
				if (code == 200) {
					this.$message.success("操作成功！");
					this.getInitFn()
				}
			});
		},

		// 点击新增按钮
		addClick () {
			this.visibleShow = true
			this.titleSize = '新增活动'
		},

		// 编辑新增按钮
		editClick (record) {
			this.form.id = record.id,
			this.bannerImg = record.image.split(',')
			this.form.sort = record.sort
			this.form.herf = record.herf
			this.form.name = record.title
			this.form.enableFlag = record.enableFlag
			this.form.startTime = record.startTime
			this.form.endTime = record.endTime
			this.form.dateTime = [record.startTime, record.endTime]
			this.titleSize = '编辑活动'
			this.visibleShow = true
		},

		deleteClick (record) {
			activeDelete({
				id: record.id,
			}).then(({ code }) => {
				if (code == 200) {
					this.$message.success("操作成功！");
					this.getInitFn()
				}
			});
		},

		// 上传图片
		imgBannerFn (data) {
			this.form.bannerImg = data
		},

		// 分页功能切换的回调
		showSizeChangeFn (current, pageSize) {
			this.pageNo = current;
			this.pageSize = pageSize;
			this.getInitFn();
		},

		// 原属数据获取
		getInitFn () {
			activeList({
				pageSize: this.pageSize,
				pageNo: this.pageNo
			}).then(({ code, data }) => {
				this.tableLoading = false
				this.tableData = data.list;
				this.count = data.count
			});
		},

		// 确定按钮
		handleOk () {
			let self = this
			if (this.bannerImg.length > 0) {
				this.isErrorSize = false
			} else {
				this.isErrorSize = true
				return false
			}
			self.$refs.ruleForm.validate((valid) => {
				if (valid) {
					self.form.bannerImg = self.bannerImg.toString()
					if (!self.form.id) {
						activeAdd({
							title: self.form.name,
							image: self.form.bannerImg,
							sort: self.form.sort,
							herf: self.form.herf,
							startTime: self.form.startTime,
							endTime: self.form.endTime,
							enableFlag: self.form.enableFlag,
						}).then(({ code, data }) => {
							if (code == 200) {
								self.getInitFn()
								self.visibleShow = false
								self.isNull()
							} else {
								self.$message.success(data.msg)
							}
						});
					} else {
						activeUpdate({
							id: self.form.id,
							title: self.form.name,
							image: self.form.bannerImg,
							sort: self.form.sort,
							herf: self.form.herf,
							endTime: self.form.endTime,
							startTime: self.form.startTime,
							enableFlag: self.form.enableFlag,
						}).then(({ code, data }) => {
							if (code == 200) {
								self.getInitFn()
								self.visibleShow = false
								self.isNull()
							} else {
								self.$message.success(data.msg)
							}
						});
					}
				}
			});
		},

		handleCancel () {
			this.isNull()
		},

		isNull () {
			this.form.name = ''
			this.form.id = ''
			this.form.bannerImg = ''
			this.form.sort = ''
			this.bannerImg = []
			this.form.dateTime = []
			this.form.herf = ''
			this.form.endTime = ''
			this.form.startTime = ''
			this.form.enableFlag = 1
		},

		// 回到顶部的函数
		targetFn () {
			return document.querySelector("#third_Party_order");
		},
	},
};
</script>

<style lang="less" scoped>
#third_Party_order {
	height: 100%;
	overflow: hidden;
}
.avatar {
	width: 100px;
}
</style>

