<template>
  <div id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="top">
        <a-form layout="inline">
          <a-form-item>
            <a-button icon="plus" class="margin_right20" type="primary" @click="addClick('1')">添加</a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 表格 -->
      <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id" :loading="tableLoading">
        <img v-viewer class="avatar" slot="imageUrl" slot-scope="imageUrl, record" :src="record.imageUrl" alt="封面图"/>
        <span slot="enableFlag" slot-scope="enableFlag, record">
          <a-badge v-if="record.enableFlag == 0" status="warning" text="已下架"/>
          <a-badge v-else status="success" text="已上架"/>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
          <template>
            <a-popconfirm title="确认要删除此分类吗？" ok-text="确定" cancel-text="取消" @confirm="deleteClick(record)">
              <a id="copy_memberNo" type="primary" class="margin_right10">删除</a>
            </a-popconfirm>
          </template>
          <template v-if="record.enableFlag == 0">
            <a-popconfirm title="确认要上架此条数据吗？" ok-text="确定" cancel-text="取消" @confirm="isLowerShelfClick(record)">
              <a id="copy_memberNo" type="primary">上架</a>
            </a-popconfirm>
          </template>
          <template v-else>
            <a-popconfirm title="确认要下架此条数据吗？" ok-text="确定" cancel-text="取消" @confirm="isLowerShelfClick(record)">
              <a id="copy_memberNo" type="primary">下架</a>
            </a-popconfirm>
          </template>
        </span>
      </a-table>
      
      <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
      
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100"/>
      
      <a-modal v-model="visibleShow" :title="titleSize" @ok="handleOk" @cancel="handleCancel">
        <a-form-model v-if="visibleShow" ref="ruleForm" :model="form" :rules="rules" >
          <a-form-model-item ref="name" label="名称" prop="name">
            <a-input v-model="form.name" placeholder="请输入名称" />
          </a-form-model-item>
          <a-form-model-item ref="bannerImg" label="海报样式" prop="bannerImg">
            <UpdataImg @avatarfns="imgBannerFn" :imageUrlNum="1" :imgUrl="bannerImg" :urlSize="500"></UpdataImg>
            <span v-if="isErrorSize" style="color:red">请上传图片</span>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-spin>
    <router-view/>
  </div>
</template>

<script>
import UpdataImg from "@/components/file/updataImg";
import {
  sharePosterAdd, sharePosterDelete, sharePosterEnableFlag,
  sharePosterList, sharePosterUpdate,
} from "@/request/api/appletsManage";
import MyPagination from "@/components/pagination/MyPagination";

export default {
  components: {UpdataImg, MyPagination},
  created() {
    this.getInitFn()
  },
  
  data() {
    return {
      visibleShow:false,
      isErrorSize:false,
      tableLoading:false,
      
      tip: "",
      titleSize:'',
      
      spinning: false,
      activeKey: 1,
      keepLoading: false,
      
      bannerImg:[],
      
      columns: [
        {
          title: "名称",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "封面图",
          dataIndex: "imageUrl",
          key: "imageUrl",
          scopedSlots: {customRender: "imageUrl"},
        },
        {
          title: "状态",
          dataIndex: "enableFlag",
          key: "enableFlag",
          scopedSlots: {customRender: "enableFlag"},
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime",
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: {customRender: "operation"},
        },
      ],
      tableData: [],
      
      pageNo: 1,
      pageSize: 20,
      count: 0,
      
      form:{
        id:'',
        name:'',
        bannerImg:'',
      },
      
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { max: 12, message: "最多输入12个字符", trigger: "change" },
        ],
        bannerImg: [{ required: true, message: "" }],
      },
    };
  },
  
  methods: {
    isLowerShelfClick(record){
      sharePosterEnableFlag({
        id: record.id,
        enableFlag: record.enableFlag == 0 ? 1 : 0
      }).then(({code}) => {
        if (code == 200) {
          this.$message.success("操作成功！");
          this.getInitFn()
        }
      });
    },
    
    // 点击新增按钮
    addClick(){
      this.visibleShow = true
      this.titleSize = '新增分享海报'
    },
    
    // 编辑新增按钮
    editClick(record){
      this.bannerImg = record.imageUrl.split(',')
      this.form.name = record.title
      this.form.id = record.id
      this.titleSize = '编辑分享海报'
      this.visibleShow = true
    },
    
    deleteClick(record){
      sharePosterDelete({
        id: record.id,
      }).then(({code}) => {
        if (code == 200) {
          this.$message.success("操作成功！");
          this.getInitFn()
        }
      });
    },
    
    // 上传图片
    imgBannerFn(data) {
      this.form.bannerImg = data
    },
    
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getInitFn();
    },
    
    // 原属数据获取
    getInitFn() {
      sharePosterList({
        pageSize:this.pageSize,
        pageNo:this.pageNo
      }).then(({ code, data }) => {
        this.tableLoading = false
        this.tableData = data.list;
        this.count = data.count
      });
    },
    
    // 确定按钮
    handleOk(){
      let self = this
      if(this.bannerImg.length>0){
        this.isErrorSize = false
      }else{
        this.isErrorSize = true
        return false
      }
      self.$refs.ruleForm.validate((valid) => {
        if (valid) {
          self.form.bannerImg = self.bannerImg.toString()
          if(!self.form.id){
            sharePosterAdd({
              title:self.form.name,
              imageUrl:self.form.bannerImg,
            }).then(({ code, data }) => {
              if(code == 200){
                self.getInitFn()
                self.visibleShow = false
                self.isNull()
              }else{
                self.$message.success(data.msg)
              }
            });
          }else{
            sharePosterUpdate({
              id:self.form.id,
              title:self.form.name,
              imageUrl:self.form.bannerImg,
            }).then(({ code, data }) => {
              if(code == 200){
                self.getInitFn()
                self.visibleShow = false
                self.isNull()
              }else{
                self.$message.success(data.msg)
              }
            });
          }
        }
      });
    },
  
    handleCancel(){
      this.isNull()
    },
    
    isNull(){
      this.form.name = ''
      this.form.id = ''
      this.form.bannerImg = ''
      this.bannerImg = []
    },
    
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#third_Party_order");
    },
  },
};
</script>

<style lang="less" scoped>
#third_Party_order {
	height: 100%;
	overflow: hidden;
}
.avatar{
  width: 100px;
}
</style>
