<template>
    <div class="padding24 color_white" id="third_Party_order">
        <div class="isDisplay" v-show="$route.meta.isShow">
            <a-tabs v-model="activeKey">
                <a-tab-pane :key="1" tab="直播介绍">
                  <LiveIntroduce></LiveIntroduce>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="Banner管理">
                  <banner></banner>
                </a-tab-pane>
                <a-tab-pane :key="3" tab="私域入口管理">
                  <pDomain></pDomain>
                </a-tab-pane>
                <a-tab-pane :key="4" tab="分享海报管理">
                  <poster></poster>
                </a-tab-pane>
                <a-tab-pane :key="5" tab="限时活动管理">
                  <moveAbout></moveAbout>
                </a-tab-pane>
                <a-tab-pane :key="6" tab="小程序活动">
                    <appMoveAbout></appMoveAbout>
                </a-tab-pane>
            </a-tabs>
        </div>
        <router-view />
    </div>
</template>

<script>
import LiveIntroduce from './components/introduce/LiveIntroduce'
import banner from './components/banner'
import pDomain from './components/pDomain'
import poster from './components/poster'
import moveAbout from './components/moveAbout'
import appMoveAbout from './components/appMoveAbout'
export default {
  components:{LiveIntroduce, banner, pDomain, poster, moveAbout, appMoveAbout},
    created () {
    },
    data() {
        return {
          activeKey:1,
        };
    },
    
    methods: {
    
    },
};
</script>

<style lang="less" scoped>
</style>

