<template>
  <div class="padding24 color_white" id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item ref="entrance" label="入口样式" prop="entrance">
          <UpdataImg @avatarfns="entranceFn" :imageUrlNum="1" :imgUrl="entranceImg" :urlSize="500"></UpdataImg>
          <span v-if="isErrorEntrance" style="color:red">请上传入口样式</span>
        </a-form-model-item>
        <a-form-model-item ref="page" label="页面样式" prop="page">
          <UpdataImg @avatarfns="pageFn" :imageUrlNum="1" :imgUrl="pageImg" :urlSize="500"></UpdataImg>
          <span v-if="isErrorPage" style="color:red">请上传页面样式</span>
        </a-form-model-item>
        <a-form-model-item ref="qrcode" label="添加二维码" prop="qrcode">
          <UpdataImg @avatarfns="qrcodeFn" :imageUrlNum="1" :imgUrl="qrcodeImg" :urlSize="500"></UpdataImg>
          <span v-if="isErrorQrcode" style="color:red">请上传二维码</span>
        </a-form-model-item>
        <a-form-model-item ref="title" label="标题" prop="title">
          <a-input v-model="form.title" placeholder="请输入标题" style="width: 200px;"/>
        </a-form-model-item>
        <FooterToolBar :collapsed="sideCollapsed">
          <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
          <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
        </FooterToolBar>
      </a-form-model>
    </a-spin>
    <router-view />
  </div>
</template>

<script>
import UpdataImg from "@/components/file/updataImg";
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import { privateAdd, privateDetail, privateUpdate } from "@/request/api/appletsManage";
export default {
  mixins: [baseMixin],
  components: { UpdataImg, FooterToolBar },
  created () {
    privateDetail({}).then(({ code, data }) => {
      if (code == 200) {
        this.form.id = data.id
        this.entranceImg = data.imageUrl.split(',')
        this.pageImg = data.posterUrl.split(',')
        this.qrcodeImg = data.qrcodeUrl.split(',')
        this.form.entranceImg = data.imageUrl
        this.form.pageImg = data.posterUrl
        this.form.qrcodeImg = data.qrcodeUrl
        this.form.title = data.title
      }
    });
  },
  data () {
    return {
      tip: "",
      spinning: false,
      activeKey: 1,
      keepLoading: false,

      isErrorEntrance: false,
      isErrorPage: false,
      isErrorQrcode: false,

      entranceImg: [],
      pageImg: [],
      qrcodeImg: [],

      form: {
        id: '',
        entranceImg: '',
        pageImg: '',
        qrcodeImg: '',
        title: ''
      },

      rules: {
        entranceImg: [{ required: true, message: "" }],
        pageImg: [{ required: true, message: "" }],
        qrcodeImg: [{ required: true, message: "" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
    };
  },

  methods: {
    entranceFn (data) {
      this.entranceImg = data
    },

    pageFn (data) {
      this.pageImg = data
    },

    qrcodeFn (data) {
      this.qrcodeImg = data
    },

    keepClick () {
      if (this.entranceImg.length > 0) {
        this.isErrorEntrance = false
      } else {
        this.isErrorEntrance = true
        return false
      }

      if (this.pageImg.length > 0) {
        this.isErrorPage = false
      } else {
        this.isErrorPage = true
        return false
      }

      if (this.qrcodeImg.length > 0) {
        this.isErrorQrcode = false
      } else {
        this.isErrorQrcode = true
        return false
      }

      this.form.entranceImg = this.entranceImg.toString()
      this.form.pageImg = this.pageImg.toString()
      this.form.qrcodeImg = this.qrcodeImg.toString()
      this.$refs.ruleForm.validate((valid) => {
        if (valid) { 
          if (!this.form.id) {
            privateAdd({
              imageUrl: this.form.entranceImg,
              posterUrl: this.form.pageImg,
              qrcodeUrl: this.form.qrcodeImg,
              title: this.form.title,
            }).then(({ code, data }) => {
              if (code == 200) {
                this.$message.success('保存成功！')
              } else {
                this.$message.success(data.msg)
              }
            });
          } else {
            privateUpdate({
              id: this.form.id,
              imageUrl: this.form.entranceImg,
              posterUrl: this.form.pageImg,
              qrcodeUrl: this.form.qrcodeImg,
              title: this.form.title,
            }).then(({ code, data }) => {
              if (code == 200) {
                this.$message.success('保存成功！')
              } else {
                this.$message.success(data.msg)
              }
            });
          }
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
#third_Party_order {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
</style>

